import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom-v5-compat";
import AuthGuard from "./components/AuthGuard";
import BrowseLayout from "./components/BrowseLayout";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import DocsLayout from "./components/docs/DocsLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";

import HookInjectorForms from "@rlmonger/forms/dist/forms/hookWrapper";
import HookInjectorPeople from "@rlmonger/people/dist/hookInjector/hookInjector";
import HookInjectorTimesheet from "@rlmonger/timesheet/dist/redux/reduxInjector";
import HookInjectorJobsites from "@rlmonger/jobsites/dist/components/hookWrapper";
import { PrefixProvider } from "@rlmonger/jobsites/dist/hooks/PrefixContext";
import HookInjectorJobcodes from "@rlmonger/jobcodes/dist/hookWrapper";
import HookInjectorGroups from "@rlmonger/groups/dist/hookWrapper";
import HookInjectorCovid from "@rlmonger/covid19/dist/covidInjector";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

import { Box } from "@mui/material";

const PEOPLE = "People";
const CODES = "Codes";
const GROUPS = "Groups";
const VEHICLES = "Vehicles";
const JOBSITES = "Jobsites";
const FORMS = "Forms";
const TICKETS = "Tickets";
const REIMBURSEMENTS = "Reimbursements";
const ORDERS = "Orders";
const TIMESHEET = "Timesheet";
const HIRES = "Hires";
const TASKS = "Tasks";
const ADMIN = "Admin";
const TAGS = "Tags";
const NEW_HIRE = "New Hire";

const DEPRECATED = "Deprecated (Do Not Use)";

const selectedPermissionGroups = [
  PEOPLE,
  CODES,
  GROUPS,
  VEHICLES,
  JOBSITES,
  FORMS,
  TIMESHEET,
  TASKS,
  ADMIN,
  TAGS,
];

import ParamsHook from "@rlmonger/forms/dist/forms/hooks/paramsHook";
import InformationPointWikiPage from "./pages/dashboard/InformationPointWiki";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const VehicleSharing = Loadable(
  lazy(() => import("./pages/VehicleSharing.tsx"))
);

// Authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const Demo = Loadable(lazy(() => import("./pages/authentication/Demo")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);

// Blog pages

// Dashboard pages
const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const Subscriptions = Loadable(
  lazy(() => import("./pages/dashboard/Subscriptions"))
);
const PurchaseSubscriptions = Loadable(
  lazy(() => import("./pages/dashboard/PurchaseSubscription"))
);
const PaymentDetails = Loadable(
  lazy(() => import("./pages/dashboard/PaymentDetails"))
);

const Dashboard = Loadable(lazy(() => import("./pages/dashboard/Dashboard")));

// Docs pages

const Docs = Loadable(lazy(() => import("./pages/Docs")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

const Assets = Loadable(lazy(() => import("./pages/dashboard/Assets")));

const DeviceDashboard = Loadable(
  lazy(() => import("./pages/dashboard/DeviceDashboard"))
);

const Environment = Loadable(
  lazy(() => import("./pages/dashboard/Environment"))
);
const VehicleManagement = Loadable(
  lazy(() => import("./pages/dashboard/Maintenance"))
);
const DriverSafety = Loadable(
  lazy(() => import("./pages/dashboard/DriverSafety"))
);
const FleetReports = Loadable(
  lazy(() => import("./pages/dashboard/FleetReports"))
);
const Reports = Loadable(lazy(() => import("./pages/dashboard/Reports")));
const ScheduledReports = Loadable(
  lazy(() => import("./pages/dashboard/ScheduledReport"))
);
const DriverManagement = Loadable(
  lazy(() => import("./pages/dashboard/DriverManagement"))
);
const DeliveryManagement = Loadable(
  lazy(() => import("./pages/dashboard/Delivery"))
);
const AccDevLink = Loadable(lazy(() => import("./pages/dashboard/AccDevLink")));
const FormCompletion = Loadable(
  lazy(() => import("@rlmonger/forms/dist/forms/formCompletion/formSelection"))
);
const Forms = Loadable(
  lazy(() => import("@rlmonger/forms/dist/forms/AdminFormsList"))
);
const FormDateViewer = Loadable(
  lazy(() => import("@rlmonger/forms/dist/formDataViewer"))
);

const BuildForm = Loadable(
  lazy(() => import("@rlmonger/forms/dist/forms/buildChecklist"))
);

const SupplierList = Loadable(
  lazy(() => import("./components/ordering/supplierListNew"))
);

const People = Loadable(lazy(() => import("./pages/dashboard/People")));

const GroupPermissions = Loadable(
  lazy(() => import("./pages/dashboard/GroupPermissions"))
);

const ReviewTimesheet = Loadable(
  lazy(() => import("@rlmonger/timesheet/dist/review"))
);
const ViewTimesheet = Loadable(
  lazy(() => import("./pages/dashboard/Timesheet"))
);

const Covid19 = Loadable(
  lazy(() => import("@rlmonger/covid19/dist/covidDataViewer"))
);
const OvertimeReport = Loadable(
  lazy(() => import("./pages/dashboard/OvertimeReport"))
);
const JobReport = Loadable(lazy(() => import("./pages/dashboard/JobReport")));

const StructureReport = Loadable(
  lazy(() => import("./pages/dashboard/StructureReport"))
);

const Jobcodes = Loadable(lazy(() => import("./pages/dashboard/Jobcodes")));

const Jobsites = Loadable(
  lazy(() => import("@rlmonger/jobsites/dist/pages/jobsites"))
);

const JobsiteDetails = Loadable(
  lazy(() => import("@rlmonger/jobsites/dist/pages/jobsite-details"))
);

const AddJobsite = Loadable(
  lazy(() => import("@rlmonger/jobsites/dist/pages/add-jobsite"))
);
const Groups = Loadable(lazy(() => import("./pages/dashboard/Groups")));

const AddGroup = Loadable(
  lazy(() => import("@rlmonger/groups/dist/groups/addGroup"))
);

const MissingShifts = Loadable(
  lazy(() => import("./pages/dashboard/MissingShifts"))
);
const NoLogin = Loadable(lazy(() => import("./pages/dashboard/NoLogin")));

const Allowances = Loadable(lazy(() => import("./pages/dashboard/Allowances")));

const ReviewForms = Loadable(
  lazy(() => import("@rlmonger/forms/dist/ReviewFormsList"))
);

// Courier pages
const CourierDashboard = Loadable(
  lazy(() => import("./pages/dashboard/courier/Dashboard"))
);
const CourierShipment = Loadable(
  lazy(() => import("./pages/dashboard/courier/Shipment"))
);

// Other pages

const CreateAutomations = Loadable(
  lazy(() => import("./pages/dashboard/automations/CreateAutomations"))
);
const Automations = Loadable(
  lazy(() => import("./pages/dashboard/automations/Automations"))
);

const Notifications = Loadable(
  lazy(() => import("./pages/dashboard/Notifications"))
);
const CompanySettings = Loadable(
  lazy(() => import("./components/companySettings/CompanySettings"))
);

const RecoverPassword = Loadable(lazy(() => import("./pages/RecoverPassword")));
const PayrollGroups = Loadable(
  lazy(() => import("./pages/dashboard/PayrollGroups"))
);

// const JobCosting = Loadable(lazy(() => import("./pages/dashboard/JobCosting")));
const JobCosting = Loadable(lazy(() => import("./pages/dashboard/JobCosting")));

// Complex full feature Plant Scheduler
// const ComplexPlantHire = Loadable(lazy(() => import("./pages/dashboard/PlantHire/ComplexPlantSchedule")));
const SimplePlantHire = Loadable(
  lazy(() => import("./pages/dashboard/PlantHire/SimplePlantSchedule"))
);

const Geofences = Loadable(lazy(() => import("./pages/dashboard/Geofences")));
const AdminLogin = Loadable(lazy(() => import("./pages/admin-login")));
const DevicesOfflinePage = Loadable(
  lazy(() => import("./pages/dashboard/DevicesOffline"))
);

const RecoverOfflineDevicePage = Loadable(
  lazy(() => import("./pages/dashboard/RecoverOfflineDevice"))
);
const CreateSupplierPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/CreateSupplier"))
);
const EvaporationLimitPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/CreateEvaporationLimits"))
);

const MaturityInputsPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/MaturityInputs"))
);

const MassConcreteLimitPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/CreateMassConcreteLimits"))
);

const CreateMixDesignPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/CreateMixDesign"))
);

const CreateMaturityInputPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/CreateMaturityInput"))
);

const MassConcreteLimitsPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/MassConcreteLimits"))
);

const MassConcreteLimitRulesPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/MassConcreteLimitRules"))
);

const CreateMassConcreteLimitRulePage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/CreateMassConcreteLimitRule"))
);

const EvaporationLimitsPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/EvaporationLimits"))
);

const MixDesignPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/MixDesigns"))
);

const PoursPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/Pours"))
);

const PourPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/Pour"))
);

const PourDeviceLinksPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/PourDeviceLinks"))
);

const PourDeviceLinkPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/PourDeviceLink"))
);

const ViewPourPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/ViewPourPage"))
);

const SensorsPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/Sensors"))
);

const SensorPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/Sensor"))
);
const ConcreteDashboardPage = Loadable(
  lazy(() => import("./pages/dashboard/concrete/ConcreteDashboard"))
);

const DashcamEventsPage = Loadable(
  lazy(() => import("./pages/dashboard/EventsDashboard"))
);
const EventDetailsPage = Loadable(
  lazy(() => import("./pages/dashboard/EventDetails"))
);

const CustomDashboard = Loadable(
  lazy(() => import("./components/dashboard/custom/Dashboard"))
);

const BeaconsList = Loadable(lazy(() => import("./pages/dashboard/Beacons")));
const BeaconConfig = Loadable(
  lazy(() => import("./pages/dashboard/BeaconConfig"))
);
const BeaconDetails = Loadable(lazy(() => import("./pages/dashboard/Beacon")));

const Trips = Loadable(lazy(() => import("./pages/dashboard/Trips")));
const Inputs = Loadable(lazy(() => import("./pages/dashboard/Inputs")));
const Timeline = Loadable(lazy(() => import("./pages/dashboard/Timeline")));
const Logbook = Loadable(lazy(() => import("./pages/dashboard/Logbook")));
const OverviewMap = Loadable(
  lazy(() => import("./pages/dashboard/OverviewMap"))
);

const BeaconOverviewMap = Loadable(
  lazy(() => import("./pages/dashboard/BeaconOverviewMap"))
);

const CompleteForm = Loadable(
  lazy(() => import("./pages/dashboard/forms/CompleteForm"))
);

const FormSubmittedThankyou = Loadable(
  lazy(() => import("./pages/dashboard/forms/CompletedFormThankyou"))
);

const AssetDashboard = Loadable(
  lazy(() => import("./pages/dashboard/AssetDashboard"))
);

const AssetDetails = Loadable(
  lazy(() => import("./pages/dashboard/AssetDetails"))
);

const BeaconDashboard = Loadable(
  lazy(() => import("./pages/dashboard/BeaconDashboard"))
);

const BeaconPanel = Loadable(
  lazy(() => import("./pages/dashboard/BeaconPanelDashboard"))
);

const DashboardForms = Loadable(
  lazy(() => import("./components/dashboard/DashboardForms"))
);

const InformationPointsPage = Loadable(
  lazy(() => import("./pages/dashboard/InformationPoints"))
);

const CreateInformationPointPage = Loadable(
  lazy(() => import("./pages/dashboard/CreateInformationPoint"))
);

const PublicInformationPointPage = Loadable(
  lazy(() => import("./pages/dashboard/PublicInformationPoint"))
);

const DriverTagsListPage = Loadable(
  lazy(() => import("./pages/dashboard/DriverTags"))
);

const DriverTagDetailsPage = Loadable(
  lazy(() => import("./pages/dashboard/DriverTagDetails"))
);

const WarboardPage = Loadable(lazy(() => import("./pages/dashboard/Warboard")));

const TaskTemplatesPage = Loadable(
  lazy(() => import("./pages/dashboard/VehicleManagement/TaskTemplates"))
);

const TasksPage = Loadable(
  lazy(() => import("./pages/dashboard/VehicleManagement/Tasks"))
);

const TaskGroupsPage = Loadable(
  lazy(() => import("./pages/dashboard/VehicleManagement/TaskGroups"))
);

const VehicleDashboardPage = Loadable(
  lazy(() => import("./pages/dashboard/VehicleManagement/VehicleDashboard"))
);

const VehicleDistancePage = Loadable(
  lazy(() => import("./pages/dashboard/VehicleDistance/VehicleDistance"))
);

const VehicleDistanceMobilePage = Loadable(
  lazy(() => import("./pages/dashboard/VehicleDistance/RoomList"))
);

const TicketsMatrix = Loadable(
  lazy(() => import("@rlmonger/people/dist/competency/matrix"))
);

const TicketsAdmin = Loadable(
  lazy(() => import("@rlmonger/people/dist/competency/competencyTable"))
);

const TicketApprovals = Loadable(
  lazy(() =>
    import("@rlmonger/people/dist/competency/requestTickets/TicketApprovals")
  )
);

const routes = ({ permissions, navigate }) => {
  return [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "demo",
      element: <Demo />,
    },
    {
      path: "register",
      element: <Register />,
    },

    {
      path: "forms/:id",
      element: (
        <HookInjectorForms
          token={localStorage.accessToken}
          children={<CompleteForm />}
        />
      ),
    },
    {
      path: "form-submitted-thankyou",
      element: (
        <HookInjectorForms
          token={localStorage.accessToken}
          children={<FormSubmittedThankyou />}
        />
      ),
    },

    {
      path: "assets/dashboard/:imei",
      element: <AssetDashboard />,
    },

    {
      path: "insight-tag/:uuid",
      element: <PublicInformationPointPage />,
    },

    {
      path: "assets/details/:imei",
      element: <AssetDetails />,
    },
    {
      path: "beacons/dashboard/:uuid",
      element: <BeaconDashboard />,
    },

    {
      path: "admin_login",
      element: <AdminLogin />,
    },
    {
      path: "courier",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <CourierDashboard />,
        },
        {
          path: "shipment/:id",
          element: <CourierShipment />,
        },
      ],
    },
    {
      path: "recoverAccount",
      element: <RecoverPassword />,
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "fleet",
          element: <Dashboard />,
        },
        {
          path: "",
          element: <Navigate to="/dashboard/assets" />,
        },
        {
          path: "account",
          element: <Account />,
        },
        {
          path: "subscriptions",
          element: <Subscriptions />,
        },
        {
          path: "purchase-subscriptions",
          element: <PurchaseSubscriptions />,
        },
        {
          path: "dashboard-forms",
          element: <DashboardForms />,
        },
        {
          path: "payment-details",
          element: <PaymentDetails />,
        },
        {
          path: "trips",
          element: <Trips />,
        },
        {
          path: "overview-map",
          element: <OverviewMap />,
        },
        {
          path: "beacon-overview-map",
          element: <BeaconOverviewMap />,
        },
        {
          path: "inputs",
          element: <Inputs />,
        },
        {
          path: "timeline",
          element: <Timeline />,
        },
        {
          path: "logbook",
          element: <Logbook />,
        },
        {
          path: "geofences",
          element: <Geofences />,
        },
        {
          path: "insight-tag/:uuid",
          element: <CreateInformationPointPage />,
        },
        {
          path: "insight-tags/:uuid/wiki",
          element: <InformationPointWikiPage />,
        },
        {
          path: "insight-tag",
          element: <CreateInformationPointPage />,
        },
        {
          path: "insight-tags",
          element: <InformationPointsPage />,
        },
        {
          path: "company_settings",
          element: <CompanySettings />,
        },
        {
          path: "assets",
          children: [
            {
              path: "",
              element: <Assets />,
            },
            {
              path: "beacon/:uuid",
              element: <BeaconPanel />,
            },
            {
              path: "device/:imei",
              element: <DeviceDashboard />,
            },
          ],
        },
        {
          path: "beacons",
          element: <BeaconsList />,
        },
        {
          path: "beacon-config",
          element: <BeaconConfig />,
        },
        {
          path: "beacon",
          element: <BeaconDetails />,
        },
        {
          path: "beacon/:uuid",
          element: <BeaconDetails />,
        },
        {
          path: "createAutomations/:id",
          element: <CreateAutomations />,
        },
        {
          path: "dashcam-events",
          element: <DashcamEventsPage />,
        },
        {
          path: "dashcam-events/:id",
          element: <EventDetailsPage />,
        },
        {
          path: "createAutomations",
          element: <CreateAutomations />,
        },
        {
          path: "automations",
          element: <Automations />,
        },
        {
          path: "environment",
          element: <Environment />,
        },
        {
          path: "driversafety",
          element: <DriverSafety />,
        },
        {
          path: "fleetreports",
          element: <FleetReports />,
        },
        {
          path: "warboard",
          element: <WarboardPage />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "scheduled-reports/:id",
          element: <ScheduledReports />,
        },
        {
          path: "vehicle-maintenance",
          element: <VehicleManagement />,
        },
        {
          path: "task-templates",
          element: <TaskTemplatesPage />,
        },
        {
          path: "tasks",
          element: <TasksPage />,
        },
        {
          path: "task-groups",
          element: <TaskGroupsPage />,
        },
        {
          path: "vehicle-dashboard/:imei",
          element: <VehicleDashboardPage />,
        },
        {
          path: "vehicle-distance",
          element: <VehicleDistancePage />,
        },
        {
          path: "vehicle-distance-mobile",
          element: <VehicleDistanceMobilePage />,
        },
        {
          path: "drivermanagement",
          element: <DriverManagement />,
        },
        {
          path: "driver-tags",
          element: <DriverTagsListPage />,
        },
        {
          path: "driver-tag",
          element: <DriverTagDetailsPage />,
        },
        {
          path: "driver-tag/:id",
          element: <DriverTagDetailsPage />,
        },
        {
          path: "plant-hire",
          element: <SimplePlantHire />,
        },
        {
          path: "deliverymanagement",
          element: <DeliveryManagement />,
        },
        {
          path: "suppliers",
          element: <SupplierList />,
        },
        {
          path: "concrete",
          children: [
            {
              path: "create-supplier",
              element: <CreateSupplierPage />,
            },
            {
              path: "mix-design",
              element: <CreateMixDesignPage />,
            },
            {
              path: "mix-design/:id",
              element: <CreateMixDesignPage />,
            },
            {
              path: "mass-concrete-limit",
              element: <MassConcreteLimitPage />,
            },
            {
              path: "mass-concrete-limit/:id",
              element: <MassConcreteLimitPage />,
            },
            {
              path: "mass-concrete-limit/:id/rules",
              element: <MassConcreteLimitRulesPage />,
            },
            {
              path: "mass-concrete-limit/:id/rule",
              element: <CreateMassConcreteLimitRulePage />,
            },
            {
              path: "mass-concrete-limit/:id/rule/:mass_concrete_limit_id",
              element: <CreateMassConcreteLimitRulePage />,
            },
            {
              path: "mass-concrete-limits",
              element: <MassConcreteLimitsPage />,
            },
            {
              path: "evaporation-limits",
              element: <EvaporationLimitsPage />,
            },
            {
              path: "evaporation-limit",
              element: <EvaporationLimitPage />,
            },
            {
              path: "evaporation-limit/:id",
              element: <EvaporationLimitPage />,
            },
            {
              path: "maturity-inputs",
              element: <MaturityInputsPage />,
            },
            {
              path: "maturity-input",
              element: <CreateMaturityInputPage />,
            },
            {
              path: "maturity-input/:id",
              element: <CreateMaturityInputPage />,
            },
            {
              path: "pours",
              element: <PoursPage />,
            },
            {
              path: "view-pour/:id",
              element: <ViewPourPage />,
            },
            {
              path: "pour",
              element: <PourPage />,
            },
            {
              path: "pour/:id",
              element: <PourPage />,
            },
            {
              path: "pour-device-links/:id",
              element: <PourDeviceLinksPage />,
            },
            {
              path: "pour-device-link/:id",
              element: <PourDeviceLinkPage />,
            },
            {
              path: "pour-device-link/:id/:imei",
              element: <PourDeviceLinkPage />,
            },
            {
              path: "sensors",
              element: <SensorsPage />,
            },
            {
              path: "sensor",
              element: <SensorPage />,
            },
            {
              path: "sensor/:id",
              element: <SensorPage />,
            },
            {
              path: "dashboard",
              element: <ConcreteDashboardPage />,
            },
            {
              path: "mix-designs",
              element: <MixDesignPage />,
            },
          ],
        },
        {
          path: "forms",
          children: [
            {
              path: "admin",
              element: (
                <HookInjectorForms
                  token={localStorage.accessToken}
                  children={
                    <Box sx={{ p: 2 }}>
                      <Forms
                        navigate={(tab) => {
                          navigate(`/dashboard/forms/${tab}`, {
                            replace: true,
                          });
                        }}
                        editForm={(view, form) => {
                          navigate(
                            `/dashboard/forms/buildform/${form.formid}`,
                            { replace: true }
                          );
                        }}
                        changeTab={() => {
                          navigate("/dashboard/forms/buildform", {
                            replace: true,
                          });
                        }}
                      />
                    </Box>
                  }
                />
              ),
            },
            {
              path: "selection",
              element: (
                <HookInjectorForms
                  token={localStorage.accessToken}
                  children={<FormCompletion />}
                />
              ),
            },
            {
              path: "review",
              element: (
                <HookInjectorForms
                  token={localStorage.accessToken}
                  children={
                    <Box sx={{ p: 2 }}>
                      <ReviewForms />
                    </Box>
                  }
                />
              ),
            },
            {
              path: "buildform/:formid",
              element: (
                <HookInjectorForms
                  token={localStorage.accessToken}
                  children={<ParamsHook children={<BuildForm />} />}
                />
              ),
            },
            {
              path: "buildform",
              element: (
                <HookInjectorForms
                  token={localStorage.accessToken}
                  children={<BuildForm />}
                />
              ),
            },
            {
              path: "completed",
              element: (
                <HookInjectorForms
                  token={localStorage.accessToken}
                  children={
                    <Box sx={{ p: 2 }}>
                      <FormDateViewer />
                    </Box>
                  }
                />
              ),
            },
          ],
        },
        {
          path: "notifications",
          element: <Notifications />,
        },
        {
          path: "devices-offline",
          element: <DevicesOfflinePage />,
        },
        {
          path: "recover-offline-device/:imei",
          element: <RecoverOfflineDevicePage />,
        },
        {
          path: "tickets",
          children: [
            {
              path: "admin",
              element: (
                <>
                  {permissions && (
                    <HookInjectorPeople
                      token={localStorage.accessToken}
                      children={<TicketsAdmin />}
                    />
                  )}
                </>
              ),
            },
            {
              path: "matrix",
              element: (
                <>
                  {permissions && (
                    <HookInjectorPeople
                      token={localStorage.accessToken}
                      children={<TicketsMatrix />}
                    />
                  )}
                </>
              ),
            },
            {
              path: "approvals",
              element: (
                <>
                  {permissions && (
                    <HookInjectorPeople
                      token={localStorage.accessToken}
                      children={<TicketApprovals />}
                    />
                  )}
                </>
              ),
            },
          ],
        },
        {
          path: "timesheet",
          children: [
            {
              path: "view",
              element: (
                <>
                  {permissions && (
                    <HookInjectorTimesheet
                      token={localStorage.accessToken}
                      children={
                        <LocalizationProvider
                          style={{ width: "100%" }}
                          dateAdapter={AdapterMoment}
                        >
                          <ViewTimesheet
                            version={5}
                            permissions={permissions}
                          />
                        </LocalizationProvider>
                      }
                    />
                  )}
                </>
              ),
            },
            {
              path: "payroll",
              element: (
                <>
                  {permissions && (
                    <HookInjectorTimesheet
                      token={localStorage.accessToken}
                      children={
                        <LocalizationProvider
                          style={{ width: "100%" }}
                          dateAdapter={AdapterMoment}
                        >
                          <ViewTimesheet
                            permissions={permissions}
                            admin={true}
                          />
                        </LocalizationProvider>
                      }
                    />
                  )}
                </>
              ),
            },
            {
              path: "review",
              element: (
                <>
                  {permissions && (
                    <HookInjectorTimesheet
                      token={localStorage.accessToken}
                      children={
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <ReviewTimesheet
                            version={5}
                            permissions={permissions}
                          />
                        </LocalizationProvider>
                      }
                    />
                  )}
                </>
              ),
            },
            {
              path: "reports",
              children: [
                {
                  path: "job",
                  element: (
                    <>
                      {permissions && (
                        <HookInjectorTimesheet
                          token={localStorage.accessToken}
                          children={
                            <JobReport version={5} permissions={permissions} />
                          }
                        />
                      )}
                    </>
                  ),
                },
                {
                  path: "missingShifts",
                  element: (
                    <>
                      {permissions && (
                        <HookInjectorTimesheet
                          token={localStorage.accessToken}
                          children={<MissingShifts permissions={permissions} />}
                        />
                      )}
                    </>
                  ),
                },
                {
                  path: "noLogin",
                  element: (
                    <>
                      {permissions && (
                        <HookInjectorTimesheet
                          token={localStorage.accessToken}
                          children={<NoLogin permissions={permissions} />}
                        />
                      )}
                    </>
                  ),
                },
                {
                  path: "structure",
                  element: (
                    <>
                      {permissions && (
                        <HookInjectorTimesheet
                          token={localStorage.accessToken}
                          children={
                            <StructureReport
                              version={5}
                              permissions={permissions}
                            />
                          }
                        />
                      )}
                    </>
                  ),
                },
                {
                  path: "overtime",
                  element: (
                    <>
                      {permissions && (
                        <HookInjectorTimesheet
                          token={localStorage.accessToken}
                          children={
                            <OvertimeReport
                              version={5}
                              permissions={permissions}
                            />
                          }
                        />
                      )}
                    </>
                  ),
                },
                {
                  path: "covid19",
                  element: (
                    <>
                      {permissions && (
                        <HookInjectorCovid
                          token={localStorage.accessToken}
                          children={<Covid19 permissions={permissions} />}
                        />
                      )}
                    </>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "admin",
          children: [
            {
              path: "accdevlink",
              element: <AccDevLink />,
            },
            {
              path: "allowances",
              element: (
                <HookInjectorTimesheet
                  token={localStorage.accessToken}
                  children={<Allowances />}
                />
              ),
            },
            {
              path: "payrollgroups",
              element: (
                <HookInjectorGroups
                  token={localStorage.accessToken}
                  children={<PayrollGroups />}
                />
              ),
            },
            {
              path: "people",
              element: (
                <>
                  {permissions && (
                    <HookInjectorPeople
                      token={localStorage.accessToken}
                      children={
                        <People
                          selectedPermissionGroups={selectedPermissionGroups}
                          changeTab={(tab) => {
                            if (tab === "groupPermissions") {
                              navigate(`/dashboard/admin/grouppermissions`, {
                                replace: true,
                              });
                            }
                          }}
                          permissions={permissions}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
            {
              path: "grouppermissions",
              element: (
                <>
                  {permissions && (
                    <HookInjectorPeople
                      token={localStorage.accessToken}
                      children={
                        <GroupPermissions
                          selectedPermissionGroups={selectedPermissionGroups}
                          permissions={permissions}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
            {
              path: "jobsites",
              element: (
                <>
                  {permissions && (
                    <PrefixProvider prefix="/dashboard/admin">
                      <HookInjectorJobsites
                        token={localStorage.accessToken}
                        permissions={permissions}
                        children={<Jobsites permissions={permissions} />}
                      />
                    </PrefixProvider>
                  )}
                </>
              ),
            },
            {
              path: "jobsite/:id/:primary?/:secondary?",
              element: (
                <>
                  {permissions && (
                    <PrefixProvider prefix="/dashboard/admin">
                      <HookInjectorJobsites
                        token={localStorage.accessToken}
                        permissions={permissions}
                        children={<JobsiteDetails containerStyles={{ p: 2 }} />}
                      />
                    </PrefixProvider>
                  )}
                </>
              ),
            },
            {
              path: "add-jobsite",
              element: (
                <>
                  {permissions && (
                    <PrefixProvider prefix="dashboard/admin">
                      <HookInjectorJobsites
                        token={localStorage.accessToken}
                        children={
                          <AddJobsite
                            changeTab={(tab) => {
                              if (tab === "addJobsite") {
                                navigate(`/dashboard/admin/addjobsite`, {
                                  replace: true,
                                });
                              } else if (tab === "jobsites") {
                                navigate(`/dashboard/admin/jobsites`, {
                                  replace: true,
                                });
                              }
                            }}
                            permissions={permissions}
                          />
                        }
                      />
                    </PrefixProvider>
                  )}
                </>
              ),
            },
            {
              path: "groups",
              element: (
                <>
                  {permissions && (
                    <HookInjectorGroups
                      token={localStorage.accessToken}
                      children={
                        <Groups
                          changeTab={(tab) => {
                            if (tab === "addGroup") {
                              navigate(`/dashboard/admin/addgroup`, {
                                replace: true,
                              });
                            } else if (tab === "groups") {
                              navigate(`/dashboard/admin/groups`, {
                                replace: true,
                              });
                            }
                          }}
                          permissions={permissions}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
            {
              path: "addgroup",
              element: (
                <>
                  {permissions && (
                    <HookInjectorGroups
                      token={localStorage.accessToken}
                      children={
                        <AddGroup
                          changeTab={(tab) => {
                            if (tab === "addGroup") {
                              navigate(`/dashboard/admin/addgroup`, {
                                replace: true,
                              });
                            } else if (tab === "groups") {
                              navigate(`/dashboard/admin/groups`, {
                                replace: true,
                              });
                            }
                          }}
                          permissions={permissions}
                        />
                      }
                    />
                  )}
                </>
              ),
            },
            {
              path: "jobcodes",
              element: (
                <>
                  {permissions && (
                    <HookInjectorJobcodes
                      token={localStorage.accessToken}
                      children={<Jobcodes permissions={permissions} />}
                    />
                  )}
                </>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "docs",
      element: <DocsLayout />,
      children: [
        {
          path: "",
          element: <Navigate to="/docs/overview/welcome" replace />,
        },
        {
          path: "*",
          element: <Docs />,
        },
      ],
    },
    {
      path: "sharing/:id",
      element: <VehicleSharing />,
      children: [
        {
          path: ":id",
          element: <VehicleSharing />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/dashboard" />,
      children: [
        {
          path: "401",
          element: <AuthorizationRequired />,
        },
        {
          path: "404",
          element: <NotFound />,
        },
        {
          path: "500",
          element: <ServerError />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ];
};

export default routes;
